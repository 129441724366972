<template>
<component v-bind:is="isCard ? 'el-card' : 'div'" class="movie">
  <div style="display: flex;">
    <div class="img-box">
      <img alt="" :src="movie.coverMaoyan" style="min-height: 80px;"/>
      <div v-if="showEdit">
        <el-button type="text" size="mini" @click="$emit('edit', movie)">编辑</el-button>
        <el-button type="text" size="mini" @click="$emit('delete', movie.id)">删除</el-button>
      </div>
    </div>
    <div class="info">
      <div class="title">{{movie.title}}</div>
      <div class="year"><span style="margin-right: 8px;">{{movie.type}}</span>{{movie.year}}</div>
      <div class="release"><span>上映：</span><date-time :time="movie.releaseDate" format="YYYY-MM-DD"/></div>
      <div>
        <span>猫眼：</span>
        <template v-if="movie.maoyanId">
          <a :href="`https://www.maoyan.com/films/${movie.maoyanId}`" target="_blank">普通</a>
          <a :href="`https://piaofang.maoyan.com/movie/${movie.maoyanId}`" target="_blank">专业版</a>
        </template>
        <el-tag v-else type="danger" size="mini">未绑定</el-tag>
      </div>
      <div>
        <span>豆瓣：</span>
        <template v-if="movie.doubanId">
          <a :href="`https://movie.douban.com/subject/${movie.doubanId}/`" target="_blank">打开</a>
        </template>
        <el-tag v-else type="danger" size="mini">未绑定</el-tag>
      </div>
      <div>
        <span>IMDB：</span>
        <template v-if="movie.imdbId">
          <a :href="`https://www.imdb.com/title/${movie.imdbId}/?ref_=tt_rt`" target="_blank">打开</a>
          <a :href="`https://www.boxofficemojo.com/title/${movie.imdbId}/?ref_=bo_se_r_1`" target="_blank">bo</a>
        </template>
        <el-tag v-else type="danger" size="mini">未绑定</el-tag>
      </div>
      <div>
        <span>百科：</span>
        <template v-if="movie.baikeUrl">
          <a :href="movie.baikeUrl" target="_blank">打开</a>
        </template>
        <el-tag v-else type="danger" size="mini">未绑定</el-tag>
      </div>
      <div>
        <span>百度指数：</span>
        <template v-if="movie.baiduUrl">
          <a :href="movie.baiduUrl" target="_blank">打开</a>
        </template>
        <el-tag v-else type="danger" size="mini">未绑定</el-tag>
      </div>
      <div>
        <span>爱奇艺：</span>
        <template v-if="movie.iqiyiId1">
          <a :href="`https://www.iqiyi.com/v_${movie.iqiyiId1}.html`" target="_blank">打开</a>
          <a :href="`https://index.iqiyi.com/q/?name=${movie.title}&aid=${movie.iqiyiId2}`" target="_blank">指数</a>
        </template>
        <el-tag v-else type="danger" size="mini">未绑定</el-tag>
      </div>
      <div>
        <span>腾讯：</span>
        <template v-if="movie.tengxunUrl">
          <a :href="movie.tengxunUrl" target="_blank">打开</a>
        </template>
        <el-tag v-else type="danger" size="mini">未绑定</el-tag>
      </div>
      <div>
        <span>优酷：</span>
        <template v-if="movie.youkuUrl">
          <a :href="movie.youkuUrl" target="_blank">打开</a>
        </template>
        <el-tag v-else type="danger" size="mini">未绑定</el-tag>
      </div>
      <div>
        <span>芒果：</span>
        <template v-if="movie.mgtvUrl">
          <a :href="movie.mgtvUrl" target="_blank">打开</a>
        </template>
        <el-tag v-else type="danger" size="mini">未绑定</el-tag>
      </div>
      <div>
        <span>抖音话题：</span>
        <template v-if="movie.douyinTopicId">
          <a :href="`https://trendinsight.oceanengine.com/arithmetic-index/analysis?topic=${movie.douyinTopicId}`" target="_blank">打开</a>
        </template>
        <el-tag v-else type="danger" size="mini">未绑定</el-tag>
      </div>
    </div>
  </div>
  <div class="count">
    <div class="t">参与公司：</div>
    <div class="i">
      <div>
        <span>出品（{{movie.chupinCount}}）</span>
        <span>联合出品（{{movie.lhchupinCount}}）</span>
      </div>
      <div>
        <span>发行（{{movie.faxingCount}}）</span>
        <span>联合发行（{{movie.lhfaxingCount}}）</span>
      </div>
    </div>
  </div>
  <div class="company">
    <div v-for="com in movie.samples" :key="com.msId">
      <div class="com">
        <el-tag type="info" size="mini">{{com.type === 'faxing' ? '发行' : com.type === 'lhfaxing' ? '联合发行' : com.type === 'chupin' ? '出品' : com.type === 'lhchupin' ? '联合出品' : '错误类型' }}</el-tag>
        <span class="name"  @click="$showSample(com.sampleId)">{{com.name}}</span>
        <a :href="`https://piaofang.maoyan.com/companydetail/${com.maoyanId}`" v-if="com.maoyanId" target="_blank">猫眼</a>
      </div>
    </div>
  </div>
</component>
</template>

<script>
export default {
  name: 'Movie',
  props: {
    isCard: { type: Boolean, default: true },
    movie: { type: Object, required: true },
    showEdit: { type: Boolean, default: false }
  }
}
</script>

<style scoped lang="scss">
.flex {
  display: flex;
.flex-1 {  flex-grow: 1; flex-shrink: 0; flex-basis: 50% }
}
.movie {
  .img-box {
    width: 100px; min-height: 150px; margin-right: 10px;
    img { width: 100%; }
  }
  .info {
    flex: 1;  white-space: nowrap;
    div { font-size: 12px; line-height: 14px !important; height: 14px !important; margin-bottom: 3px; }
    .el-tag--mini { line-height: 14px !important; height: 14px !important; }
    a { color: #333; margin-right: 5px;  }
    .title { font-weight: 600; font-size: 14px; margin-bottom: 4px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  }
  .count {
    font-size: 12px; display: flex; margin-top: 4px; border-top: 1px solid #eee; padding-top: 6px;
    .i { flex: 1; div { margin-bottom: 3px; span:first-child { margin-right: 5px;} }}
  }
  .company {
    margin-top: 4px;
    .com {
      white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 12px;
      margin-bottom: 2px;
      a { color: #333; margin-left: 5px;  }
      .el-tag { line-height: 16px; height: 18px; }
    }
    .name { margin-left: 6px; cursor: pointer; }
  }
}

</style>
