<template>
  <div class="account-container">
    <div class="header">
    </div>
    <el-card class="account-box">
      <div class="box">
        <div class="panel">
          <div class="title"><h1>指数综合调查系统</h1></div>
          <router-view />
        </div>
      </div>
    </el-card>
    <div class="footer">
      <div class="note">请使用谷歌或火狐浏览器，或者将浏览器升级到Microsoft Edge</div>
    </div>
  </div>
</template>
<script>
import cover from '../assets/cover.jpg'

export default {
  name: 'AccountLayOut',
  data () {
    return { cover, siteTitle }
  }
}
</script>
<style lang="scss" scoped>
  @import "../style/var.scss";
  .account-container {
    display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%;
    .header {
      flex: 1; display: flex; flex-direction: column-reverse;
      // h1 { font-size: 38px; letter-spacing: 3px; font-weight: bold; padding-bottom: 20px; color: #555; }
    }
    .footer {
      flex: 1; display: flex; flex-direction: column-reverse;
      font-size: 13px;
      color: #888;
      text-align: center;
      .com { display: inline-block; padding: 0 10px;  }
      .note { margin-top: 12px; padding-bottom: 10px; }
    }
  }
  .account-box {
    ::v-deep .el-card__body { padding: 0 !important; }
    .title {
      font-size: 24px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      font-weight: 600;
      letter-spacing: 1px;
    }
    .box {
       display: flex;
       .img { width: 300px; display: flex; justify-content: center; align-items: center;border-right: 1px solid #eee; }
       .panel { padding: 20px 60px 20px 40px; }
    }
  }
</style>
