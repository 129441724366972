<style lang="scss" scoped>
  .c {
     padding: 10px 80px 0px 20px;
  }
  .title {
    font-size: 20px; padding: 0 0 30px 80px;
  }
</style>
<template>
<div class="c">
  <div class="title">欢迎登录</div>
  <el-form ref="form" :model="form" :rules="rules" hide-required-asterisk @submit.native.prevent="doLogin" label-width="80px">
    <el-form-item label="用户名" prop="username">
      <el-input placeholder="请输入用户名"  v-model="form.username" clearable></el-input>
    </el-form-item>
    <el-form-item label="登录密码" prop="password">
      <el-input placeholder="请输入登录密码" v-model="form.password" show-password></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" native-type="submit" style="width: 100%;" size="medium" :loading="posting">登录</el-button>
      <div style="text-align: right;">
        <el-link href="#/account/reset_password">忘记密码</el-link>
        <!-- <el-divider direction="vertical"></el-divider>
        <el-link href="#/account/register">还没有账号？点此注册</el-link> -->
      </div>
      <!-- <div style="text-align: right;">
        <el-link href="#/account/change">调查负责人变更，点此操作</el-link>
      </div> -->
    </el-form-item>
  </el-form>
</div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      form: { username: '', password: '' },
      rules: {
        username: [
          { required: true, message: '请输入用户名' }
        ],
        password: [
          { required: true, message: '请输入登录密码' }
        ]
      },
      posting: false
    }
  },
  methods: {
    ...mapActions('account', ['login']),
    doLogin () {
      const redirect = this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : '/'
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.posting = true
        this.login({ ...this.form })
          .then(res => {
            this.$message({ type: 'success', message: '登录成功' })
            this.posting = false
            setTimeout(() => {
              this.$router.replace(redirect)
            }, 500)
          }).catch(() => {
            this.posting = false
          })
      })
    }
  }
}
</script>
